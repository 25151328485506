<template>
  <div class="sign_container">
    <div class="sign_header">
        <div class="sign_logo_block">
            <img src="@/assets/images/head_logo.jpg" alt="">GTOMaster
        </div>
    </div>
    <div class="sign_content">
        <div class="sign_wrapper">
            <div class="form_block" v-if="!isReset">
                <p class="form_title">{{isSignUp ? 'Create your account' : 'Sign in to your account'}}</p>
                <p class="form_subTitle">account allows you to access the App</p>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="top">
                    <el-form-item label="Email" prop="userName">
                        <el-input v-model="ruleForm.userName" placeholder="Enter Your Email"></el-input>
                    </el-form-item>
                    <el-form-item label="Password" prop="password" 
                        :rules="[{
                            required:true,
                            trigger: ['blur', 'change'],
                            validator: (rule, value, callback) => validator(rule, value, callback,'input')
                        }]"
                    >
                        <el-input v-model="ruleForm.password" show-password :placeholder="isSignUp ? 'Created password' : 'Enter Your Password'"></el-input>
                    </el-form-item>
                    <el-form-item label="Confirm password" prop="conPassword" v-if="isSignUp"
                        :rules="[{
                            required:true,
                            trigger: ['blur', 'change'],
                            validator: (rule, value, callback) => validator(rule, value, callback,'confirm')
                        }]"
                    >
                        <el-input v-model="ruleForm.conPassword" show-password placeholder="Enter created password"></el-input>
                    </el-form-item>
                </el-form>
                <!-- <el-checkbox v-model="checked">I have read and agree to 
                    <a href="">Terms of Use </a>and<a href=""> Refund Policy</a>
                </el-checkbox> -->
                <p class="form_submit" @click="submitForm('ruleForm')">{{isSignUp ? 'Sign up' : 'Sign in'}}</p>
                <p class="forgot_password" v-if="!isSignUp" @click="isReset = true">Forgot password?</p>
                <div class="toggle_sign">{{this.isSignUp ? "Already have an account?" : "Don't have an account yet?"}}
                    <span class="sign_up" @click="isSignUp = !isSignUp">{{isSignUp ? 'Sign in' : 'Sign up'}}</span>
                </div>
            </div>
            <div class="reset_block" v-if="isReset">
                <p class="form_title">Forgot password</p>
                <el-form :model="resetForm" :rules="rules" ref="resetForm" label-width="100px" label-position="top">
                    <el-form-item label="Email" prop="userName">
                        <el-input v-model="resetForm.userName" placeholder="Enter Your Email"></el-input>
                    </el-form-item>
                </el-form>
                <p class="form_submit" @click="resetSubmit('resetForm')">Forgot password</p>
                <div class="toggle_sign">Remember your password?
                    <span class="sign_up" @click="isReset = false">Back to login</span>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import JSEncrypt from 'jsencrypt'
import API from "@/api/home.js"
import { publicKey } from './data'
export default {
    data() {
        return {
            checked: false,
            isReset: false,
            isSignUp:false,// 是否为注册
            ruleForm: {
                userName:'',
                password:'',
                conPassword:''
            },
            resetForm:{
                userName:'',
            },
            rules: {
                userName: [
                    { required: true, message: 'Please input your email', trigger: 'blur' },
                    { type: 'email', message: 'Please enter the correct email address', trigger: ['blur', 'change'] }
                ],
                password: [
                    { required: true, message: 'Please input your password', trigger: 'blur' },
                ],
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid) {
                    return this.$message.error('You should accept terms to continue')
                }
                let apiName = this.isSignUp ? 'appRegister' : 'appLogin'
                let resData = await API[apiName]({
                    userName: this.ruleForm.userName,
                    password: this.encryptPassword(this.ruleForm.password),
                }).catch(err => {
                    return this.$message.error(err.message)
                })
                if(!resData.success) {
                    return this.$message.error(resData.msg || (this.isSignUp ? 'Account registration failed' : 'Account login failed'))
                }
                let newData = resData.data || {}
                let userInfo = JSON.stringify(newData)
                sessionStorage.setItem('userInfo', userInfo);
                localStorage.setItem('userInfo', userInfo);
                this.$router.replace({ path: "/" })
            });
        },
        resetSubmit(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid) {
                    return this.$message.error('You should accept terms to continue')
                }
                let resData = await API.forgetPass({
                    userName: this.resetForm.userName
                }).catch(err => {
                    return this.$message.error(err.message)
                })
                if(!resData.success) {
                    return this.$message.error(resData.msg)
                }
                this.isReset = false;
            });
        },
        validator(rule, value, callback,type) {
            if(!value) return callback(`Please ${type} your password`);
            if(this.isSignUp && this.ruleForm.password !== this.ruleForm.conPassword) {
                return callback('The two passwords that you entered do not match')
            }
            callback();
        },
        encryptPassword(password) {
            const encrypt = new JSEncrypt();
            encrypt.setPublicKey(publicKey);
            return encrypt.encrypt(password);
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/signView.scss';
</style>
<style>
.form_block .el-form-item__label{
    font-size: 14px;
    line-height: 1.5715;
    color: rgba(255,255,255,0.85);
}
.form_block .el-checkbox__label {
    white-space: break-spaces;
    vertical-align: text-top;
    color: #fff;
}
.form_block .el-checkbox__label a {
    color: #43E090;
}
</style>